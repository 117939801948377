/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    BaseResponseModel,
    BooleanNullableModelResponse,
    ColumnFilter,
    CorporationFeeScheduleDto,
    FeeSchedulesDto,
    FeeScheduleType,
    FeeScheduleVersionDto,
    GeneralStatus,
    MarkupType,
    SortType,
} from "./data-contracts";
import {ContentType, HttpClient, RequestParams} from "./http-client";

export class FeeScheduleVersion<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags FeeScheduleVersion
     * @name FeeScheduleSheetIsProcessingList
     * @request GET:/FeeScheduleVersion/FeeScheduleSheet/IsProcessing
     */
    feeScheduleSheetIsProcessingList = (params: RequestParams = {}) =>
        this.request<BooleanNullableModelResponse, any>({
            path: `/FeeScheduleVersion/FeeScheduleSheet/IsProcessing`,
            method: "GET",
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags FeeScheduleVersion
     * @name FeeScheduleSheetImportCreate
     * @request POST:/FeeScheduleVersion/FeeScheduleSheet/Import
     */
    feeScheduleSheetImportCreate = (data: { File?: File; FeeSchedule?: FeeSchedulesDto }, params: RequestParams = {}) => {
        const current = {
            File: data.File,
            Feeschedule: JSON.stringify(data.FeeSchedule)
        }

        return this.request<BaseResponseModel, any>({
            path: `/FeeScheduleVersion/FeeScheduleSheet/Import`,
            method: "POST",
            body: current,
            type: ContentType.FormData,
            format: "json",
            ...params,
        });
    }
    /**
     * No description
     *
     * @tags FeeScheduleVersion
     * @name GetActiveVersionList
     * @request GET:/FeeScheduleVersion/GetActiveVersion
     */
    getActiveVersionList = (query?: { feeScheduleId?: number }, params: RequestParams = {}) =>
        this.request<ModelResponse<FeeScheduleVersionDto>, any>({
            path: `/FeeScheduleVersion/GetActiveVersion`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags FeeScheduleVersion
     * @name PostFeeScheduleVersion
     * @request POST:/FeeScheduleVersion/Add
     */
    postPost = (data: FeeScheduleVersionDto, params: RequestParams = {}) =>
        this.request<ModelResponse<FeeScheduleVersionDto>, any>({
            path: `/FeeScheduleVersion/Add`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags FeeScheduleVersion
     * @name LoadList
     * @request GET:/FeeScheduleVersion/Load
     */
    loadList = (
        query?: {
            Take?: number;
            Skip?: number;
            IncludeIds?: number[];
            "OrderBy.Field"?: string;
            "OrderBy.Type"?: SortType;
            SearchTerm?: string;
            ColumnFilters?: ColumnFilter[];
            Status?: GeneralStatus;
            PracticeId?: number;
            ForDropdown?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<ListResponseModel<FeeScheduleVersionDto>, any>({
            path: `/FeeScheduleVersion/Load`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags FeeScheduleVersion
     * @name GetByIdList
     * @request GET:/FeeScheduleVersion/GetById
     */
    getByIdList = (query?: { id?: number }, params: RequestParams = {}) =>
        this.request<ModelResponse<FeeScheduleVersionDto>, any>({
            path: `/FeeScheduleVersion/GetById`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags FeeScheduleVersion
     * @name DeleteDelete
     * @request DELETE:/FeeScheduleVersion/Delete
     */
    deleteDelete = (query?: { id?: number }, params: RequestParams = {}) =>
        this.request<ModelResponse<FeeScheduleVersionDto>, any>({
            path: `/FeeScheduleVersion/Delete`,
            method: "DELETE",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags FeeScheduleVersion
     * @name UpdateCreate
     * @request POST:/FeeScheduleVersion/Update
     */
    updateCreate = (data: FeeScheduleVersionDto, params: RequestParams = {}) =>
        this.request<ModelResponse<FeeScheduleVersionDto>, any>({
            path: `/FeeScheduleVersion/Update`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
}
